<template>
  <div class="job-detail" @click.stop v-if="detail">
    <div class="title">
      <p>岗位详情</p>
    </div>
    <div class="info-content">
      <div class="infoList" v-if="detail.employer">
        <span>单位名称：</span>
        <p>{{detail.employer}}</p>
      </div>
      <div class="infoList" v-if="detail.institutionalAttributes">
        <span>机构属性：</span>
        <p>{{detail.institutionalAttributes}}</p>
      </div>
      <div class="infoList" v-if="detail.positionName">
        <span>职位名称：</span>
        <p>{{detail.positionName}}</p>
      </div>
      <div class="infoList" v-if="detail.jobCode">
        <span>职位代码：</span>
        <p>{{detail.jobCode}}</p>
      </div>
      <div class="infoList" v-if="detail.jobProfile">
        <span>职位简介：</span>
        <p>{{detail.jobProfile}}</p>
      </div>
      <div class="infoList" v-if="detail.examCategory">
        <span>考试类型：</span>
        <p>{{detail.examCategory}}</p>
      </div>
      <div class="infoList" v-if="detail.numberCandidates">
        <span>招考人数：</span>
        <p>{{detail.numberCandidates}}</p>
      </div>
      <div class="infoList" v-if="detail.profession">
        <span>专业：</span>
        <p>{{detail.profession}}</p>
      </div>
      <div class="infoList" v-if="detail.education">
        <span>学历：</span>
        <p>{{detail.education}}</p>
      </div>
      <div class="infoList" v-if="detail.departmentName">
        <span>部门名称：</span>
        <p>{{detail.departmentName}}</p>
      </div>
      <div class="infoList" v-if="detail.institutionalHierarchy">
        <span>机构层级：</span>
        <p>{{detail.institutionalHierarchy}}</p>
      </div>
      <div class="infoList" v-if="detail.positionAttribute">
        <span>职位属性：</span>
        <p>{{detail.positionAttribute}}</p>
      </div>
      <div class="infoList" v-if="detail.registeredCount">
        <span>已报名人数：</span>
        <p>{{detail.registeredCount}}</p>
      </div>
      <div class="infoList" v-if="detail.degree">
        <span>学位：</span>
        <p>{{detail.degree}}</p>
      </div>
      <div class="infoList" v-if="detail.politicalStatus">
        <span>政治面貌：</span>
        <p>{{detail.politicalStatus}}</p>
      </div>
      <div class="infoList" v-if="detail.minimumYearsGrassrootsWork">
        <span>基层工作最低年限：</span>
        <p>{{detail.minimumYearsGrassrootsWork}}</p>
      </div>
      <div class="infoList" v-if="detail.threeSupportsAndOneAssistance">
        <span>三支一扶：</span>
        <p>{{detail.threeSupportsAndOneAssistance}}</p>
      </div>
      <div class="infoList" v-if="detail.westernVolunteers">
        <span>西部志愿者：</span>
        <p>{{detail.westernVolunteers}}</p>
      </div>
      <div class="infoList" v-if="detail.universityStudentVillageOfficial">
        <span>大学生村官：</span>
        <p>{{detail.universityStudentVillageOfficial}}</p>
      </div>
      <div class="infoList" v-if="detail.specialPostTeacher">
        <span>特岗计划教师：</span>
        <p>{{detail.specialPostTeacher}}</p>
      </div>
      <div class="infoList" v-if="detail.unlimited">
        <span>无限制：</span>
        <p>{{detail.unlimited}}</p>
      </div>
      <div class="infoList" v-if="detail.whetherToOrganizeProfessionalExaminations">
        <span>是否组织专业考试：</span>
        <p>{{detail.whetherToOrganizeProfessionalExaminations}}</p>
      </div>
      <div class="infoList" v-if="detail.ratioOfInterviewCandidatesToThePlannedNumberOfHires">
        <span>面试人选与计划录用人数的确定比例：</span>
        <p>{{detail.ratioOfInterviewCandidatesToThePlannedNumberOfHires}}</p>
      </div>
      <div class="infoList" v-if="detail.otherConditions">
        <span>其他条件：</span>
        <p>{{detail.otherConditions}}</p>
      </div>
      <div class="infoList" v-if="detail.remarks">
        <span>备注：</span>
        <p>{{detail.remarks}}</p>
      </div>
      <div class="infoList" v-if="detail.affiliation">
        <span>隶属：</span>
        <p>{{detail.affiliation}}</p>
      </div>
      <div class="infoList" v-if="detail.areaCode">
        <span>地区代码：</span>
        <p>{{detail.areaCode}}</p>
      </div>
      <div class="infoList" v-if="detail.age">
        <span>年龄：</span>
        <p>{{detail.age	}}</p>
      </div>
      <div class="infoList" v-if="detail.gender">
        <span>性别：</span>
        <p>{{detail.gender}}</p>
      </div>
      <div class="infoList" v-if="detail.phone">
        <span>电话：</span>
        <p>{{detail.phone}}</p>
      </div>
      <div class="infoList" v-if="detail.placeName">
        <span>地区名：</span>
        <p>{{detail.placeName}}</p>
      </div>
    </div>
    <div class="btn-box">
      <span @click="onClose()">关闭</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobDetail',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods:{
    onClose() {
      this.$emit('onClose', false)
    }
  }
}
</script>

<style lang="less" scoped>
.job-detail{
  width:94%;
  margin:0.5rem 3%;
  background: #fff;
  border-radius: 0.1rem;
  padding: 0.2rem 0.35rem;
  max-height: 80%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
}
.title{
  flex-grow: 0;
  flex-shrink: 0;
}
.title p{
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8rem;
  text-align: center;
}
.info-content{
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}
.info-content .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.8rem;
  text-align: left;
  justify-content: flex-start;
  border-bottom: 1px #f2f2f5 solid;
}
.info-content .infoList span{
  width:28%;
  color:#999;
  flex-grow: 0;
  flex-shrink: 0;
}
.btn-box{
  height:1.2rem;
  width:100%;
  text-align: center;
}
.btn-box span{
  padding:0.2rem 0.5rem;
  background: #ff1b1b;
  color:#fff;
  font-size: 14px;
  border-radius: 5px;
}
</style>
<style>
.van-overlay{
  z-index:1000;
}
</style>
